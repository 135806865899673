/*
 * @Name         : 系统定时器
 * @Author       : Jaron Jia
 * @Company      : JaronSoft & DCSDATA
 * @Date         : 2020-07-22 16:18:44
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-05 16:20:33
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/utils/useInterval.ts
 * @ 以下公司保留代码的一切权利
 * (C)2007-2020 扬州市佳融信息技术有限公司
 * (C)2015-2020 扬州运帷数据技术有限公司
 */

import { useEffect, useRef } from 'react';

/**
 * 在 React 函数组件中创建间隔定时器的自定义钩子。
 *
 * @param {() => void} callback - 每个间隔执行的函数。
 * @param {number | null} delay - 间隔时间，单位为毫秒。如果为 null，则暂停间隔。
 */
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<(() => void) | null>(null);

  // 记住最新的回调函数。
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // 设置间隔。
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      // 在清理时清除间隔。
      return () => clearInterval(id);
    }
  }, [delay]);
}
