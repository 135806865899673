/*
 * @Name         :
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft & DCSDATA
 * @Date         : 2023-11-27 19:57:05
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-02 22:29:57
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/components/Footer/index.tsx
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */
import * as React from "react";
import styles from "./index.module.css";
import dayjs from "dayjs";

export default function Footer() {
  const currentYear = dayjs().year();

  return (
    <p className={styles.footer}>
      <span className={styles.logo}>
        <img src="/images/logo/jaronsoft_login_logo.svg" />
      </span>
      <br />
      <span className={styles.copyright}>
        (C)2007-{currentYear},JARONSOFT/DCSDATA LLC.{" "}
      </span>
    </p>
  );
}
