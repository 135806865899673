/*
 * @Name         : 
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft LLC & DCSDATA LLC
 * @Date         : 2023-12-04 21:16:48
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-11 22:17:34
 * @FilePath     : \sgis.1-1-0.com.cn\v2\src\components\HeaderAvatar\DialogOperation.tsx
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */
import { IUserChangePassword } from '@/interfaces/user';
import { Dialog } from '@alifd/next';
import { FunctionComponent, useCallback, useRef } from 'react';
import ChangePassword from './ChangePassword';

// 定义 DialogOperation 的属性类型
interface DialogOperationProps {
  actionType: 'add' | 'edit' | 'preview';
  dataSource: IUserChangePassword;
  onOk?: (values: IUserChangePassword | null) => void;
}

// 根据 actionType 获取对话框标题
const dialogTitles = {
  add: '添加',
  edit: '修改口令',
  preview: '预览'
};

const getDialogTitle = (actionType: 'add' | 'edit' | 'preview'): string => {
  return dialogTitles[actionType] || dialogTitles.add;
};

/**
 * @Name DialogOperation
 * @Description 对话框操作组件，用于处理不同类型的密码操作（添加、编辑、预览）
 * @Params {DialogOperationProps} props - 组件的属性，包括操作类型、数据源和确认操作回调
 * @returns React 组件
 */
const DialogOperation: FunctionComponent<DialogOperationProps> = ({ actionType, dataSource, onOk = () => {}, ...lastProps }) => {
  const operationRef = useRef<any>(null);

  /**
   * @Name handleOk
   * @Description 确认操作的处理函数。如果是预览模式，直接调用 onOk；否则，获取并处理表单数据。
   */
  const handleOk = useCallback(() => {
    if (actionType === 'preview') {
      onOk(null);
      return;
    }
    operationRef.current.getValues((values: IUserChangePassword) => {
      onOk(values);
    });
  }, [actionType, onOk]);

  return (
    <Dialog 
      overflowScroll 
      footerAlign="center" 
      onOk={handleOk} 
      title={getDialogTitle(actionType)} 
      style={{ width: 480 }}
      {...lastProps}
    >
      <ChangePassword
        ref={operationRef}
        actionType={actionType}
        dataSource={dataSource}
      />
    </Dialog>
  );
};

export default DialogOperation;
