/*
 * @Author       : Jaron Jia
 * @Company      : JaronSoft & DCSDATA
 * @Date         : 2020-07-13 16:41:27
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-05 01:12:48
 * @FilePath     : \sgis.1-1-0.com.cn\v2\src\components\Notice\index.tsx
 * @ 以下公司保留代码的一切权利
 * (C)2007-2020 扬州市佳融信息技术有限公司
 * (C)2015-2020 扬州运帷数据技术有限公司
 */
import { getCookie } from '@/utils/cookieutils';
import { useInterval } from '@/utils/useInterval';
import { Avatar, Badge, Icon, List, Message, Overlay } from '@alifd/next';
import dayjs from 'dayjs';
import { Link, request } from 'ice';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';

const { Popup } = Overlay;
const refreshInterval = 60000; // 多久刷新一次
const refreshLong = false; // 是否设置永久定时读取,如果是，下面参数无效
const refreshT = 10; // 上面参数设置为true时，刷新多少次就会永久停止，每隔1分钟刷新，则刷新10次后自动停止

const defaultNoticeList = []; // 数据数据

const Notice = ({ noticeList }) => {
  const [badgeCount, setBageCount] = useState(0); // 小图标数量
  const [readList, setReadList] = useState([]);
  const [initReadList, setInitReadList] = useState([]);
  const [second, setSecond] = useState(refreshT); // 设置拉取频率

  useEffect(() => {
    // GetUnreadMessageList().then(responseData => {
    //   if (responseData.tag == 1) {
    //     noticeList = responseData.data;
    //     setBageCount(responseData.total);
    //     setReadList(responseData.data);
    //     // setInitReadList(noticeList);
    //     console.log('[' + dayjs().format('HH:mm:ss') + '] 初始化,读取未邮件列表...')
    //   }
    // });

    return () => {
    };
  }, []);

  async function RefreshBageCount() {
    GetUnreadCount().then(data => {
      console.log('正在刷新未读邮件数量...')
      if (data.tag == 1) {
        setBageCount(data.data[0].itemValue);
        //加载未读邮件，最多5条
      }
    });
  }

  /** 读取未读邮件数量 */
  async function GetUnreadCount() {
    const responseData = await request.post("/Messenger/GetMessageCount", {
      UserId: getCookie("userid"),
      IsReaded: 0
    });
    return responseData.data;
  }

  /**
   * @Function Name:  读取未读邮件列表
   * @Description:
   * @Params:
   * @Returns:
   */

  async function UnreadMessageList() {
    GetUnreadMessageList().then((responseData) => {
      console.log(`[${dayjs().format('HH:mm:ss')}] 正在获取未读邮件...`);
      if (responseData.tag == 1) {
        setBageCount(responseData.total);
        setReadList(responseData.data);
        if (refreshLong) setSecond(refreshT); // 用户设置永久循环
      }
    });
  }
  /**
   * @Name: 读取未读邮件列表
   * @Description:
   * @Params:
   * @Returns:
   */
  async function GetUnreadMessageList() {
    const responseData = await request.post('/Messenger/GetPageListJson', {
      param: {
        Receiver: getCookie('UID'), IsRead: 0,
      },
      pagination: {},
    });
    return responseData.data;
  }

  useInterval(() => {
    // console.log('[' + dayjs().format('HH:mm:ss') + '] 计时器', second)
    setSecond(second - 1);
    if (second <= 0) UnreadMessageList();
  }, second >= 0 ? refreshInterval : null);

  async function markAsRead(id) {
    const responseData = await request.post('/Messenger/MarkAsReaded', { Id: id, Receiver: getCookie('UID') });
    // console.log('respd', responseData)
    if (responseData.data.tag === 1) {
      // setReadList([...readList, id]);
      UnreadMessageList();
      setBageCount(badgeCount - 1);
    }
  }

  function clearNotice() {
    const noticeIds = noticeList.map((item) => item.id);
    setBageCount(0);
    setReadList(noticeIds);
    Message.success('仅临时清空数据,未对邮件数据进行操作');
  }

  function viewMore() {
    Message.success('此功能暂不支持');
  }

  // const renderList = initReadList.filter(item => readList.indexOf(item.id) === -1);
  return (
    <Popup
      trigger={
        <div className={styles.noticeIcon}>
          <Badge count={badgeCount}><Icon type="email" /></Badge>
        </div>
      }
      triggerType="click"
    >
      <List size="small" divider className={styles.noticeContainer} header={
        <div className={styles.title}>
          <h4>通知</h4>
          <span className={styles.clear} onClick={clearNotice}>清空通知</span>
        </div>
      }
        footer={
          <div className={styles.footer}>
            <Link to="/Users/Messenger">点击查看更多</Link>
          </div>
        }
      >
        {
          readList.map((noticeItem) => {
            const { id, messageTitle, messageContent, baseCreateTime } = noticeItem;
            return (
              <List.Item className={styles.noticeItem} key={id} title={messageTitle}
                media={<Avatar size="small" style={{ backgroundColor: '#5491E0' }} icon="email" />}
                extra={
                  <span className={styles.close} onClick={() => markAsRead(id)}>
                    <Icon type="close" size="xs" />
                  </span>
                }
              >
                {messageContent} ({dayjs(baseCreateTime).format('HH:mm')})
              </List.Item>
            );
          })}
        {readList.length === 0 && (
          <List.Item className={styles.empty}>你已查看所有通知</List.Item>
        )}
      </List>
    </Popup>
  );
};

Notice.defaultProps = {
  noticeList: defaultNoticeList,
};
export default Notice;
