/*
 * @Name         : 系统菜单管理
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft LLC & DCSDATA LLC
 * @Date         : 2023-12-27 12:21:02
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-28 13:08:23
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/menuConfig.tsx
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */

const headerMenuConfig = [];
const asideMenuConfig = [
  {
    name: "用户登录",
    path: "/Auth/Login",
    icon: "chart-pie",
  },
  {
    name: "工作台",
    authorities: ["user", "admin"],
    path: "/",
    icon: "chart-pie",
  },
  {
    name: "事件业务管理",
    authorities: ["user", "admin"],
    icon: "list",
    children: [
      { name: "待办事件", path: "/Event/General/Deal", icon: "set" },
      { name: "事件列表", path: "/Event/General/List", icon: "set" },
    ]
  },
  {
    name: "矛盾纠纷管理",
    authorities: ["user", "admin"],
    icon: "detail",
    children: [
      { name: "待办事件", path: "/Event/Conflict/Deal", icon: "set" },
      { name: "事件列表", path: "/Event/Conflict/List", icon: "set" },
    ]
  },
  {
    name: "安全隐患管理",
    authorities: ["user", "admin"],
    icon: "switch",
    children: [
      { name: "待办事件", path: "/Event/SafeHa/Deal", icon: "set" },
      { name: "事件列表", path: "/Event/SafeHa/List", icon: "set" },
    ]
  },
  {
    name: "信访稳定管理",
    authorities: ["user", "admin"],
    icon: "ellipsis",
    children: [
      { name: "待办事件", path: "/Event/Petition/Deal", icon: "set" },
      { name: "事件列表", path: "/Event/Petition/List", icon: "set" },
    ]
  },
  {
    name: "站内消息",
    authorities: ["user"],
    path: "/Users/Messenger", icon: "email"
  },
  {
    name: "系统设置",
    icon: "set",
    authorities: ["admin", "platform"],
    children: [
      { name: "人员管理", path: "/Admin/Users/List", icon: "set" },
      {
        name: "组织架构",
        authorities: ["admin", "platform"],
        icon: "account",
        children: [
          { name: "部门管理", path: "/Admin/Organization/Department" },
          { name: "工作村管理", path: "/Admin/Organization/Gzc/List" },
          { name: "职位管理", path: "/Admin/Organization/Position" },
          { name: "角色权限", path: "/Admin/Role" },
        ],
      },
      {
        name: "新闻文章管理",
        icon: "list",
        children: [
          { name: "发布文章", path: "/Admin/SiteManager/Articles/Post/0" },
          { name: "管理文章", path: "/Admin/SiteManager/Articles/List" },
          { name: "分类管理", path: "/Admin/SiteManager/NewsCategory" },
        ],
      },
      {
        name: "系统配置",
        authorities: ["admin", "platform"],
        icon: "set",
        children: [
          { name: "多租户管理", path: "/Admin/SAAS", icon: 'account' },
          { name: "系统配置", path: "/Admin/SystemConfig", icon: 'set' },
          { name: "数据字典(新)", path: "/Admin/Dictionary", icon: 'detail' },
          { name: "数据字典(旧)", path: "/Admin/ResourcesDic", icon: 'ellipsis' },
          { name: "功能菜单配置", path: "/Admin/Menu", icon: 'list' },
          { name: "小程序功能", path: "/Admin/Function", icon: 'smile' },
          // { name: "功能菜单定义", path: "/Admin/Menu", },
          // { name: 'Saas平台管理', path: '/Admin/Saas', authorities: ["admin"] },
        ],
      },
      {
        name: "消息群发",
        authorities: ["user", "admin"],
        path: "/InMail/list", icon: "email"
      },
      {
        name: "上传文件管理",
        path: "/Admin/UserObjectManager",
        icon: "attachment",
      },
      {
        name: "安全日志",
        icon: "lock",
        children: [
          { name: "系统日志", path: "/Admin/Logs/AppLog", icon: "ellipsis" },
          { name: "操作日志", path: "/Admin/Logs/ApiLog", icon: "semi-select" },
        ],
      },
      { name: "软件注册", path: "/Admin/RegisterLisence", icon: "copy" },
      { name: "系统信息", path: "/Admin/SysInfo", icon: "warning" },
      {
        name: "自动群发",
        path: "/Admin/SendMessage",
        icon: "set",
        authorities: ["admin", "platform"],
      },
      {
        name: "表单",
        path: "/form",
        icon: "copy",
      },
      {
        name: "列表",
        path: "/list",
        icon: "chart-bar",
      },
      {
        name: "结果&异常",
        icon: "warning",
        children: [
          {
            name: "成功",
            path: "/success",
          },
          {
            name: "404",
            path: "/404",
          },
        ],
      },
    ],
  },
];
export { headerMenuConfig, asideMenuConfig };
