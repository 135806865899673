/*
 * @Name         : 用户修改口令表单
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft LLC & DCSDATA LLC
 * @Date         : 2023-12-04 21:16:48
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-11 22:23:16
 * @FilePath     : \sgis.1-1-0.com.cn\v2\src\components\HeaderAvatar\ChangePassword.tsx
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */

import { Field, Form, Input, Message } from '@alifd/next';
import React, { ForwardedRef, useImperativeHandle } from 'react';
import { IUserChangePassword } from '@/interfaces/user'
const FormItem = Form.Item;
const formItemLayout = { labelCol: { span: 4 }, wrapperCol: { span: 20 } };


// 定义组件的 Props 类型
interface OperationProps {
  actionType?: string;
  dataSource: IUserChangePassword; // 新添加的属性
}

/**
 * @Name Operation
 * @Description 用户密码操作表单组件
 * @Params {OperationProps} props - 组件属性
 * @Params {ForwardedRef<any>} ref - 用于父组件获取子组件的方法和属性
 * @returns React 组件
 */
const Operation = React.forwardRef((props: OperationProps, ref: ForwardedRef<any>) => {
  const { actionType, dataSource } = props; // 使用 dataSource 属性
  const field = Field.useField([]);

  useImperativeHandle(ref, () => ({
    /**
     * @Name getValues
     * @Description 获取表单值，并进行校验
     * @Params {Function} callback - 表单验证通过后的回调函数
     */
    getValues(callback: (values: IUserChangePassword) => void) {
      field.validate((errors, values) => {
        if (errors) return;
        const typedValues = values as IUserChangePassword;
        if (typedValues.oldPassword === typedValues.newPassword) {
          Message.warning('新密码与原密码一致，请重新输入新密码');
          return;
        }
        callback(values as IUserChangePassword);
      });
    },
  }));

  /**
   * @Name checkConfirmpwd
   * @Description 校验两次密码输入是否一致
   * @Params {Object} rule - 校验规则
   * @Params {String} value - 当前输入的值
   * @Params {Function} callback - 校验的回调函数
   */
  function checkConfirmpwd(rule, value, callback) {
    const values = field.getValues() as IUserChangePassword;
    if (values.newPassword !== values.confirmPassword) {
      callback('两次密码输入不一致');
    } else {
      callback();
    }
  }

  const isPreview = actionType === 'preview';
  return (
    <Form isPreview={isPreview} fullWidth labelAlign={isPreview ? 'left' : 'top'} field={field} {...formItemLayout}>
      <FormItem label="原密码:" required requiredMessage="请输入原密码:">
        <Input name="oldPassword" htmlType="password" placeholder="请输入原密码" />
      </FormItem>
      <FormItem label="新密码:" required requiredMessage="请输入新密码:">
        <Input name="newPassword" htmlType="password" placeholder="请输入新密码" />
      </FormItem>
      <FormItem label="确认密码:" required requiredMessage="请确认新密码:" validator={checkConfirmpwd} validatorTrigger="onBlur">
        <Input name="confirmPassword" htmlType="password" placeholder="请确认新密码" />
      </FormItem>
    </Form>
  );
});

export default Operation;
