/*
 * @Name         : 
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft & DCSDATA
 * @Date         : 2023-11-27 19:57:05
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-25 20:54:14
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/components/Logo/index.tsx
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */
import * as React from 'react';
import { Link } from 'ice';
import styles from './index.module.css';

export interface ILogoProps {
  image?: string;
  text?: string;
  url?: string;
  imageStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

export default function Logo({ image, text, url, imageStyle, textStyle }: ILogoProps) {
  return (
    <div className={styles.logoContent}>
      <Link to={url || '/'} className={styles.logo}>
        {image && <img src={image} alt="logo" style={imageStyle} />}
        <span className={styles.softwareTitle}>{text}</span>
      </Link>
    </div>
  );
}
