import { getCookie } from '@/utils/cookieutils';
import { Avatar, Icon, Menu, Message, Overlay } from '@alifd/next';
import { useSetState } from 'ahooks';
import { Link, request, useNavigate } from 'ice';
import { useCallback, useEffect, useState } from 'react';
import DialogOperation from './DialogOperation';
import styles from './index.module.scss';

const HeaderAvatar = (props) => {
  const { Item } = Menu;
  const { Popup } = Overlay;
  const UserProfile = ({ uid, name, avatar, deptname }) => {
    if (uid) {
      return (
        <div className={styles.profile}>
          <div className={styles.avatar}>
            <Avatar src={avatar} alt="用户头像" />
          </div>
          <div className={styles.content}>
            <h4>{name}</h4>
            <span>{deptname}</span>
          </div>
        </div>
      )
    } else {
      return <></>
    }
  };

  const ListMenu = ({ uid, name, avatar, deptname }) => {
    if (uid) {
      return (
        <Menu className={styles.menu}>
          <Item>
            <Icon size="small" type="account" />
            <Link to="/Users/Profile">个人设置</Link>
          </Item>
          <Item onClick={() => { operationCallback({ actionType: 'edit', dataSource: null }); }}>
            <Icon size="small" type="set" />
            修改口令
          </Item>
          <Item>
            <Icon size="small" type="prompt" />
            <Link to="/Auth/Logout">安全退出</Link>
          </Item>
        </Menu>
      )
    } else {
      return (
        <Menu className={styles.menu}>
          <Item>
            <Icon size="small" type="exit" />
            <Link to="/Auth/Login">用户登录</Link>
          </Item>
        </Menu>
      )
    }
  };


  const navigate = useNavigate();

  const [state, setState] = useSetState({ optCol: null, actionType: 'preview', actionVisible: false });
  const { actionVisible, columnWidth, optCol } = state;
  const [userinfo, setUserInfo] = useState({
    uid: null,
    avatar: getCookie('PORTRAIT') || '/favicon.png',
    name: getCookie('REALNAME') || '未登录用户',
    deptname: getCookie('DEPARTMENT_NAME') || process.env.ICE_APP_NAME,
  });

  const operationCallback = useCallback(
    ({ actionType, dataSource }) => {
      setState({
        actionType,
        optCol: dataSource,
        actionVisible: true,
      });
      // console.log(actionType, dataSource)
    },
    [setState],
  );

  useEffect(() => {
    if (getCookie('UID')) {
      setUserInfo(prevState => {
        return { ...prevState, uid: getCookie('UID') };
      });
    }
  }, []);

  const handleCancel = useCallback(() => {
    setState({ actionVisible: false });
  }, [setState]);

  const handleOk = useCallback(async (formValue) => {
    const data = { password: formValue.oldPassword, newPassword: formValue.newPassword };
    const responseData = await request.post('/OrganizationManage/Users/ChangePassword', data);
    // console.log(responseData.data)
    if (responseData.data.tag === 1) {
      Message.success('密码修改成功，请重新登录');
      navigate('/Auth/Logout')
    } else {
      Message.error(responseData.data.message);
    }
    handleCancel();
  }, [handleCancel]);
  return (
    <div style={{ cursor: 'pointer' }}>
      <Popup triggerType="click" trigger={
        <div className={styles.profile1}>
          <div className={styles.avatar}>
            <Avatar src={userinfo.avatar} alt="用户头像" />
          </div>
          <div className={styles.content}>
            <h4>{userinfo.name}</h4>
            <h4>{userinfo.deptname}</h4>
          </div>
        </div>
      }>
        {
          userinfo.name && <div className={styles.avatarPopup}>
            <UserProfile {...userinfo} />
            <ListMenu {...userinfo} />
          </div>
        }

      </Popup>
      <DialogOperation visible={actionVisible} actionType={state.actionType} dataSource={optCol} onOk={handleOk} onClose={handleCancel} onCancel={handleCancel} />
    </div>
  );
};

export default HeaderAvatar;
